<template>
  <div class="clock-container cursor-pointer">

    <div class="clock planete-online-gradient">
      {{ formatDate(this.time, "LL") }}
    </div>
    <div class="clock planete-online-gradient">
      {{ formatDate(this.time, "LT") }}
      <span class="time-small">{{ formatDate(this.time, "ss") }}</span>
    </div>

  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "Clock",
  data() {
    return {
      time: null
    }
  },
  created() {
    this.time = moment()
    setInterval(() => {
      this.time = moment()
    })
  }
}
</script>

<style scoped lang="scss">
.clock-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.clock {
  background: var(--v-primary-base);
  padding: .2em .6em;
  border-radius: 4px;
  color: white;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  margin: 0 .5em;
  font-size: .8em;
  text-transform: uppercase;
}

.time-small {
  margin-left: .3em;
  font-size: .7em;
}

.cursor-pointer {
  cursor: pointer;
}
</style>